import React from 'react';
import './Education.css';

function Education() {
  return (
    <section className="section education" id="education">
      <br></br>
      <h2>Education</h2>
      <br></br>
      <div className="education-container">
        <div className="education-card" onClick={() => window.open('https://digitalskills.colorado.edu/lp/cybersecurity-bootcamp/?utm_source=google&utm_medium=cpc&utm_campaign=&utm_adset=&utm_ad=&utm_term=cybersecurity%20bootcamp&adpos=&device=c&creative=633057420132&placement=&cid=18825661901&asid=141046020897&kmt=e&net=g&device_model=&target=kwd-331348857343&gad_source=1&gclid=CjwKCAjw_4S3BhAAEiwA_64Yhkf2i5ANE4eb1ryyxyZxk1cJLLUVZYAYs9B8WJBrWsYZ0LJ1FsV-zBoCyJUQAvD_BwE', '_blank')}>
        <img src={`${process.env.PUBLIC_URL}/images/CU.jpg`} alt="Profile Picture" style={{ height: '150px', width: 'auto' }}/>
          <h3>Cybersecurity Bootcamp</h3>
          <p><strong>University of Colorado, Boulder, CO</strong> <br /> Jun 2024 - Present</p>
          {/* <p><em>Relevant Coursework:</em> Information Security, Network Security, Risk Management...</p> */}
        </div>

        <div className="education-card" onClick={() => window.open('https://www.online.colostate.edu/degrees/computer-science-bachelors/', '_blank')}>
          <img src={`${process.env.PUBLIC_URL}/images/CSU.jpeg`} alt="Profile Picture" style={{ height: '150px', width: 'auto' }} /> 
          <h3>B.S. Computer Science</h3>
          <p><strong>Colorado State University, Fort Collins, CO</strong> <br /> Aug 2020 - Aug 2024</p>
          {/* <p><strong>Concentration:</strong> Network and Security <br />
            <strong>Minor:</strong> Business Administration <br />
            <strong>Certifications:</strong> Entrepreneurship</p> */}
          {/* <p><em>Relevant Coursework:</em> Personal Computing, Data Structures, Algorithms...</p> */}
        </div>
        <br></br>
      </div>
    </section>
  );
}

export default Education;

import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Education from './components/Education';
import Certifications from './components/Certifications';
// import Projects from './components/Projects';
import Expierence from './components/Experience';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Modal from './components/Modal';

function App() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true); 
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Mason Coco's Portfolio</title>
        <meta name="description" content="Explore Mason Coco's portfolio featuring projects and accomplishments in web development, cybersecurity, and IT support." />
        <meta name="keywords" content="Mason Coco, portfolio, web development, cybersecurity, IT support, projects, React, Node.js" />
        <meta name="author" content="Mason Coco" />
      </Helmet>

      <Navbar />
      <Hero />
      <About />
      <Education />
      <Expierence/>
      <Certifications />
      <Contact />
      <Footer />

      {/* {showModal && <Modal onClose={() => setShowModal(false)} />} */}
    </div>
  );
}

export default App;

import React from 'react';
import './Experience.css';

function Experience() {
  return (
    <section className="section experience" id="experience">
      <br></br>
      <h2>Experience</h2>
      <br></br>
      <div className="current-experience-card" onClick={() => window.open('https://coleengineering.com/', '_blank')}>
          <img src={`${process.env.PUBLIC_URL}/images/CESI.png`} alt="Company Logo" />
          <h3>Jr. Test Engineer</h3>
          <p><strong>Cole Engineering Services Inc.</strong> <br /> Hanover, MD <br /> Oct 2024 - Present</p>
          {/* <p><em>Key Responsibilities:</em> Developed and implemented...</p> */}
        </div>
      <div className="experience-container">
        <div className="experience-card" onClick={() => window.open('https://beaverworks.ll.mit.edu/CMS/bw/bwsi', '_blank')}>
        <img src={`${process.env.PUBLIC_URL}/images/bwsi.png`} alt="Company Logo" style={{ height: '150px', width: 'auto' }} />
          <h3>Cyber Operations Teaching Assistant</h3>
          <p><strong>Massachusetts Institute of Technology</strong> <br /> Remote <br /> Jun 2022 - Aug 2024</p>
          {/* <p><em>Key Responsibilities:</em> Managed projects, collaborated...</p> */}
        </div>
        <div className="experience-card" onClick={() => window.open('https://compsci.colostate.edu/', '_blank')}>
          <img src={`${process.env.PUBLIC_URL}/images/CSU.png`} alt="Company Logo" style={{ height: '150px', width: 'auto' }}/>
          <h3>Cybersecurity Research Assistant</h3>
          <p><strong>Colorado State University</strong> <br /> Fort Collins, CO <br /> Jun 2022 - Aug 2024</p>
          {/* <p><em>Key Responsibilities:</em> Managed projects, collaborated...</p> */}
        </div>
        <br></br>
      </div>
    </section>
  );
}

export default Experience;
